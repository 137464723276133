<template>
  <div class="pt-2 container-fluid">
    <div class="row mb-5 px-3">
      <div class="col">
        <material-input
          id="search"
          v-model="searchTerm"
          type="text"
          label="Suchen..."
        />
      </div>
      <div class="col-auto">
        <material-button
          class="h-100"
          color="warning"
          variant="gradient"
          size="sm"
          @click="openSearchModal()"
        >
          <i class="material-icons text-2xl">manage_search</i>
        </material-button>
      </div>
      <div class="col-auto">
        <material-button
          class="h-100"
          color="warning"
          variant="gradient"
          size="sm"
          @click="openAddModal()"
        >
          <i class="material-icons text-2xl">post_add</i>
        </material-button>
      </div>
    </div>

    <div class="row mt-3">
      <div
        v-for="recipe in recipes"
        :key="recipe.id"
        class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-5"
      >
        <recipe-card
          :recipe="recipe"
          :img="recipe.imgUrl"
          :title="recipe.title"
          :description="recipe.subtitle"
          :location="recipe.preparationTime + ' Min.'"
          :did="recipe.did"
          price="Vegetatisch"
        />
      </div>
    </div>
  </div>

  <div
    id="recipe-search-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="h5 modal-title">
            Rezepte suchen
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body">
          <material-input
            id="search"
            v-model="searchTerm"
            type="text"
            label="Suchen..."
          />

          <div class="row mt-5">
            <div
              v-for="(recipe, index) in recipeResults"
              :key="recipe.id"
              class="col-lg-3 col-md-6 mb-5"
            >
              <recipe-search-card
                :img="recipe.imgUrl"
                :title="recipe.title"
                description=""
                :location="recipe.preparationTime + ' Min.'"
                price="Vegetatisch"
                @click="addRecipe(recipe, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="recipe-add-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="h5 modal-title">
            Rezepte hinzufügen
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body">
          <form
            role="form"
            action="#"
            class="text-start mt-3"
            @submit.prevent="addSubmit"
          >
            <div class="mb-3">
              <material-input
                id="title"
                v-model="addForm.title"
                type="text"
                label="Titel"
                name="title"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="subtitle"
                v-model="addForm.subtitle"
                type="text"
                label="Untertitel"
                name="subtitle"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="siteUrl"
                v-model="addForm.siteUrl"
                type="text"
                label="Rezept-URL"
                name="title"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="imgUrl"
                v-model="addForm.imgUrl"
                type="text"
                label="Bild-URL"
                name="imgUrl"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="preparationTime"
                v-model="addForm.preparationTime"
                type="text"
                label="Zubereitungszeit"
                name="preparationTime"
              />
            </div>
            <div class="text-center">
              <material-button
                class="my-4 mb-2"
                variant="gradient"
                color="warning"
                full-width
              >
                Hinzufügen
              </material-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <div
    id="recipe-edit-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="h5 modal-title">
            Rezepte bearbeiten
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body">
          <form
            role="form"
            action="#"
            class="text-start mt-3"
            @submit.prevent="editSubmit"
          >
            <div class="mb-3">
              <material-input
                id="title"
                v-model="editForm.title"
                type="text"
                label="Titel"
                name="title"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="subtitle"
                v-model="editForm.subtitle"
                type="text"
                label="Untertitel"
                name="subtitle"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="siteUrl"
                v-model="editForm.siteUrl"
                type="text"
                label="Rezept-URL"
                name="title"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="imgUrl"
                v-model="editForm.imgUrl"
                type="text"
                label="Bild-URL"
                name="imgUrl"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="preparationTime"
                v-model="editForm.preparationTime"
                type="text"
                label="Zubereitungszeit"
                name="preparationTime"
              />
            </div>
            <div class="text-center">
              <material-button
                class="my-4 mb-2"
                variant="gradient"
                color="warning"
                full-width
              >
                Speichern
              </material-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    id="recipe-modal-backdrop"
    class="fixed inset-0 z-40 hidden bg-black opacity-50"
  />
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import RecipeCard from "./components/RecipeCard.vue";
import RecipeSearchCard from "./components/RecipeSearchCard.vue";

import { debounce } from "debounce";
import axios from "axios";
import { db, doc, getDoc, addDoc, collection, onSnapshot, where, query, updateDoc } from "@/firebase";

export default {
  name: "DataTables",
  components: {
    RecipeCard,
    RecipeSearchCard,
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      searchTerm: '',
      recipeResults: [],
      recipes: [],
      addForm: {
        title: "",
        subtitle: "",
        siteUrl: "",
        imgUrl: "",
        preparationTime: ""
      },
      editForm: {
        title: "",
        subtitle: "",
        siteUrl: "",
        imgUrl: "",
        preparationTime: ""
      },
      recipeSearchModal: null,
      recipeAddModal: null,
      recipeEditModal: null
    };
  },
  watch: {
    searchTerm: debounce(async function(val) {
      if(val != null && val != '') {
        let response = await axios.get(this.userDoc.chefkoch_url + '-search?query=' + val);
        
        this.recipeResults = [];

        response.data.results.forEach(element => {
          if(element.recipe.previewImageId != null && this.recipeResults.length < 8) {
            element.recipe.imgUrl = "https://img.chefkoch-cdn.de/rezepte/" + element.recipe.id + "/bilder/" + element.recipe.previewImageId + "/crop-256x170/" + element.recipe.siteUrl.split("/").pop().split(".")[0] + ".jpg";
            this.recipeResults.push(element.recipe);
          }
        });
      }
    }, 500)
  },
  async beforeMount() {
    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();

      let q = query(collection(db, "recipes"), where('user', 'in', [this.$user.uid, this.userDoc.household]));
      this.unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { newIndex, oldIndex, doc, type } = change
          if (type === 'added') {
            this.recipes.splice(newIndex, 0, doc.data())
          } else if (type === 'modified') {
            this.recipes.splice(oldIndex, 1)
            this.recipes.splice(newIndex, 0, doc.data())
          } else if (type === 'removed') {
            this.recipes.splice(oldIndex, 1)
          }
        });
      }, (error) => {
        console.error(error);
      });
    }
  },
  async beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    async addRecipe(recipe, index) {
      this.recipeResults.splice(index, 1);

      let response = await axios.get(this.userDoc.chefkoch_url + '-recipe?recipeId=' + recipe.id);
      // eslint-disable-next-line require-atomic-updates
      recipe.subtitle = response.data.subtitle;
      // eslint-disable-next-line require-atomic-updates
      recipe.instructions = response.data.instructions;
      // eslint-disable-next-line require-atomic-updates
      recipe.user = this.$user.uid;

      await addDoc(collection(db, "recipes"), recipe)
        .then(async docRef => {
          console.log("Document written with ID: ", docRef.id);
          await updateDoc(docRef, {
            did: docRef.id
          });
        });
    },
    openSearchModal() {
      const bootstrap = this.$store.state.bootstrap;
      let recipeSearchModalElement = document.getElementById(
        "recipe-search-modal"
      );
      this.recipeSearchModal = new bootstrap.Modal(recipeSearchModalElement, {
        show: true
      });
      this.recipeSearchModal.show();
    },
    openAddModal() {
      const bootstrap = this.$store.state.bootstrap;
      let recipeAddModalElement = document.getElementById(
        "recipe-add-modal"
      );
      this.recipeAddModal = new bootstrap.Modal(recipeAddModalElement, {
        show: true
      });
      this.recipeAddModal.show();
    },
    async addSubmit() {
      let recipe = this.addForm;
      recipe.user = this.$user.uid;

      await addDoc(collection(db, "recipes"), recipe)
        .then(async docRef => {
          console.log("Document written with ID: ", docRef.id);
          await updateDoc(docRef, {
            did: docRef.id
          });
          this.recipeAddModal.hide();
        });
    },
    openEditModal(recipe) {
      const bootstrap = this.$store.state.bootstrap;
      
      this.editForm = recipe;

      let recipeEditModalElement = document.getElementById(
        "recipe-edit-modal"
      );
      this.recipeEditModal = new bootstrap.Modal(recipeEditModalElement, {
        show: true
      });
      this.recipeEditModal.show();
    },
    async editSubmit() {
      let recipe = this.editForm;
      recipe.user = this.$user.uid;

      const docRef = doc(db, "recipes", recipe.did);

      await updateDoc(docRef, recipe)
        .then(() => {
          this.recipeEditModal.hide();
        });
    }
  },
};
</script>
