<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-9 mb-4">
        <vue-calendar />
      </div>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header p-3 pb-0">
            <h6 class="mb-0">
              Nächste Termine
            </h6>
          </div>
          <div class="card-body border-radius-lg p-3">
            <default-item
              v-for="(event, index) in events"
              :key="event._eid"
              :class="index == 0 ? 'mt-0' : 'mt-3'"
              :icon="{ component: event.class, color: event.content == 'restaurant' ? 'success' : 'warning' }"
              :title="event.title"
              :description="formatDate(event.start)"
            />
          </div>
        </div>
        <music-player color="dark" />
        <line-chart
          id="chart-widgets-4"
          height="120"
          title="Haushaltskonto"
          class="my-4"
          :value="{
            amount: '970,00€',
            percentage: { value: '+5%', color: 'success' },
          }"
          :chart="{
            labels: [
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            datasets: {
              label: 'Income',
              data: [50, 45, 60, 60, 80, 65, 90, 80, 100],
            },
          }"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 mb-4">
        <progress-line-chart
          title="Aufgaben"
          :count="480"
          :progress="60"
          :chart="{
            labels: [
              'Apr',
              'Mai',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Okt',
              'Nov',
              'Dez',
            ],
            data: [40, 45, 42, 41, 40, 43, 40, 42, 39],
          }"
        />
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div
            v-for="(list, index) in lists.slice().reverse()"
            :key="'list' + index"
            class="col-lg-6 mb-4"
          >
            <todo-list-card
              :name="list.name"
              :pid="list.pid"
              :limit="4"
            />
          </div>
        </div>
      </div>
    </div>
    <!--<div class="row mt-4">
      <div class="col-lg-3 col-sm-6">
        <mini-statistics-card
          class="bg-white p-0"
          :title="{
            text: 'Battery Health',
            color: 'opacity-9 text-gray',
          }"
          :value="{
            text: '99 %',
            color: 'text-dark',
          }"
          :icon="{
            component: 'battery_charging_full',
            background: 'bg-success',
          }"
        />
        <mini-statistics-card
          class="bg-white p-0"
          :title="{
            text: 'Music Volume',
            color: 'opacity-7 text-gray',
          }"
          :value="{
            text: '15/100',
            color: 'text-dark',
          }"
          :icon="{
            component: 'volume_down',
            background: 'bg-success',
          }"
        />
      </div>
      <div class="col-lg-5 col-sm-6 mt-sm-0 mt-4">
        <div class="row">
          <div class="col-md-6">
            <default-info-card
              icon="account_balance"
              title="Salary"
              description="Belong Interactive"
              value="+$2000"
            />
          </div>
          <div class="col-md-6">
            <default-info-card
              icon="account_balance_wallet"
              title="Paypal"
              description="Freelance Payment"
              value="$455.00"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-3 col-md-6">
        <info-card
          title="Full Body"
          description="What matters is the people who are sparked by it. And the people who are liked."
          :badge="{ text: 'Moderate', color: 'success' }"
        />
      </div>
      <div class="col-lg-2 col-md-6">
        <controller-card
          id="switch-on"
          controller-is="On"
          :image="{
            url: bulbIcon,
            alt: 'Bulb Icon',
            label: 'Lights',
          }"
        />
      </div>
      <div class="col-lg-3" />
      <div class="col-lg-2 col-md-6">
        <mini-info-card
          icon="refresh"
          color="success"
          title="754
        <span class='text-sm text-secondary'>m</span>"
          description="New York City"
        />
      </div>
      <div class="col-lg-2 col-md-6">
        <steps-card
          title="Steps"
          count="11.4K"
          :badge="{ label: '+4.3%', color: 'success' }"
        />
      </div>
    </div>-->
    <div class="row mt-4">
      <!--<div class="col-lg-3 mt-0">
        <categories-list
          :items="[
            {
              icon: { component: 'launch', background: 'dark' },
              title: 'Devices',
              description: '250 in stock <strong>346+ sold</strong>',
            },
            {
              icon: {
                component: 'book_online',
                background: 'dark',
              },
              title: 'Tickets',
              description: '123 closed <strong>15 open</strong>',
            },
            {
              icon: { component: 'priority_high', background: 'dark' },
              title: 'Error logs',
              description: '1 is active <strong>40 closed</strong>',
            },
            {
              icon: { component: 'insert_emoticon', background: 'dark' },
              title: 'Happy Users',
              description: '+ 430',
            },
          ]"
        />
      </div>-->
      <div class="col-xl-8 col-xxl-8">
        <h6 class="font-weight-bolder">
          Neueste Einträge (Watchlist, Rezept, Wunsch)
        </h6>
        <div class="row mt-5">
          <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-4 mb-5 mb-xxl-0">
            <watchlist-card
              v-if="latestMovie"
              :img="latestMovie.poster_path"
              :title="latestMovie.title"
              :description="latestMovie.overview ? latestMovie.overview : ''"
              :price="String(latestMovie.release_date ? latestMovie.release_date : '')"
              location="Netflix"
              :did="latestMovie.did"
              type="Filme"
            />
          </div>
          <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-4 mb-5 mb-xxl-0">
            <recipe-card
              v-if="latestRecipe"
              :recipe="latestRecipe"
              :img="latestRecipe.imgUrl"
              :title="latestRecipe.title"
              :description="latestRecipe.subtitle"
              :location="latestRecipe.preparationTime + ' Min.'"
              :did="latestRecipe.did"
              price="Vegetatisch"
            />
          </div>
          <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-4 mb-5 mb-xxl-0">
            <wishlist-card
              v-if="latestWish && latestWishlist"
              :wish="latestWish"
              :wishlist-id="latestWishlist.did"
              :img="latestWish.imgUrl"
              :title="latestWish.title"
              description=""
              :price="latestWish.price + ' ' + latestWish.currency"
              :location="latestWishlist.title == 'Eigene' ? 'Extern' : 'Amazon'"
            />
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-xxl-4">
        <timeline-list
          title="Änderungsverlauf"
          description="Die letzten sechs Änderungen"
        >
          <timeline-item
            v-for="(log, index) in logs"
            :key="'logs' + index"
            :icon="log.icon"
            :icon-class="'text-' + log.color"
            :title="log.message"
            :date-time="formatDate(log.createdAt)"
          />
        </timeline-list>
      </div>
    </div>
  </div>
</template>

<script>
/*import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import InfoCard from "./components/InfoCard.vue";
import ControllerCard from "./components/ControllerCard.vue";
import MiniInfoCard from "./components/MiniInfoCard.vue";
import StepsCard from "./components/StepsCard.vue";
import CategoriesList from "@/examples/Cards/CategoriesList.vue";*/
import VueCalendar from "./components/VueCalendar.vue";
import ProgressLineChart from "./components/ProgressLineChart.vue";
import LineChart from "./components/LineChart.vue";
import DefaultItem from "./components/DefaultItem.vue";
import MusicPlayer from "./components/MusicPlayer.vue";
import TimelineList from "@/examples/Cards/TimelineList.vue";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import bulbIcon from "@/assets/img/small-logos/icon-bulb.svg";
import TodoListCard from "@/views/dots/components/TodoListCard.vue";
import WatchlistCard from "@/views/dots/components/WatchlistCard.vue";
import RecipeCard from "@/views/dots/components/RecipeCard.vue";
import WishlistCard from "@/views/dots/components/WishlistCard.vue";

import { db, onSnapshot, collection, query, orderBy, where, limit, doc, getDoc, getDocs } from "@/firebase";
import moment from 'moment';

export default {
  name: "Widgets",
  components: {
    /*MiniStatisticsCard,
    DefaultInfoCard,
    InfoCard,
    ControllerCard,
    MiniInfoCard,
    StepsCard,
    CategoriesList,*/
    VueCalendar,
    TimelineList,
    TimelineItem,
    ProgressLineChart,
    LineChart,
    DefaultItem,
    MusicPlayer,
    TodoListCard,
    WatchlistCard,
    RecipeCard,
    WishlistCard
  },
  data: () => ({
    bulbIcon,
    lists: [],
    unsubscribeLogs: null,
    unsubscribeEvents: null,
    events: [],
    logs: [],
    latestMovie: null,
    latestRecipe: null,
    latestWishlist: null,
    latestWish: null
  }),
  async beforeMount() {
    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();

      let qEvents = query(collection(db, "events"), where("start", ">", moment().valueOf()), where("user", "==", this.$user.uid), orderBy("start"), limit(4));
      this.unsubscribeEvents = onSnapshot(qEvents, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { newIndex, oldIndex, doc, type } = change;
          let e = doc.data();
          e.start = moment(e.start).format('YYYY-MM-DD HH:mm');
          e.end = moment(e.end).format('YYYY-MM-DD HH:mm');
          if (type === 'added') {
            this.events.splice(newIndex, 0, e)
          } else if (type === 'modified') {
            this.events.splice(oldIndex, 1)
            this.events.splice(newIndex, 0, e)
          } else if (type === 'removed') {
            this.events.splice(oldIndex, 1)
          }
        });
      }, (error) => {
        console.error(error);
      });

      let qLogs = query(collection(db, "logs"), where("user", "==", this.$user.uid), orderBy("createdAt", "desc"), limit(6));
      this.unsubscribeLogs = onSnapshot(qLogs, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { newIndex, oldIndex, doc, type } = change;
          let e = doc.data();
          if (type === 'added') {
            this.logs.splice(newIndex, 0, e)
          } else if (type === 'modified') {
            this.logs.splice(oldIndex, 1)
            this.logs.splice(newIndex, 0, e)
          } else if (type === 'removed') {
            this.logs.splice(oldIndex, 1)
          }
        });
      }, (error) => {
        console.error(error);
      });

      let self = this;

      const movieQ = query(collection(db, "movies"), where("user", "==", this.$user.uid), limit(1));
      const movieQSnapshot = await getDocs(movieQ);
      movieQSnapshot.forEach((docu) => {
        self.latestMovie = docu.data();
      });

      const receipeQ = query(collection(db, "recipes"), where("user", "==", this.$user.uid), limit(1));
      const receipeQSnapshot = await getDocs(receipeQ);
      receipeQSnapshot.forEach((docu) => {
        self.latestRecipe = docu.data();
      });

      const wishQ = query(collection(db, "wishlists"), where("user", "==", this.$user.uid), limit(1));
      const wishQSnapshot = await getDocs(wishQ);
      wishQSnapshot.forEach((docu) => {
        self.latestWishlist = docu.data();
        if(docu.data().items.length > 0)
          self.latestWish = docu.data().items[docu.data().items.length - 1];
      });

      const lists = this.userDoc.todoist_list_id.split(',');
      
      lists.forEach(async list => {
        self.lists.push({
          name: list.split(':')[0],
          pid: list.split(':')[1]
        });
      });
    }
  },
  async beforeUnmount() {
    this.unsubscribeLogs();
    this.unsubscribeEvents();
  },
  methods: {
    formatDate: (date) => {
      moment.locale('de');
      return moment(date).format('DD. MMMM YYYY') + ' um ' + moment(date).format('HH:mm');
    }
  },
};
</script>
