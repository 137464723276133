<template>
  <div class="card widget-calendar">
    <div class="p-0 card-body">
      <vue-cal
        locale="de"
        :time-from="6 * 60"
        :time-to="22 * 60"
        :snap-to-time="15"
        :events="events"
        :active-view="activeView"
        :disable-views="disableViews"
        :dblclick-to-navigate="false"
        :editable-events="editableEvents"
        style="height: 752px"      
        @event-change="onEventChange"
        @event-delete="onEventDelete"
        @event-create="onEventCreate"
        @event-drag-create="onEventDragCreate"
        @event-focus="onEventFocus"
        @event-title-change="onEventTitleChange"
      >
        <template #event="{ event }">
          <simple-typeahead
            v-model="event.title"
            :value="event.title"
            :items="recipes"
            :min-input-length="1"
            @on-blur="onBlur"
          />

          <!-- Will be added if `time` option is set to `true` -->
          <div class="vuecal__event-time">
            {{ event.start.formatTime('HH:mm') }} - {{ event.end.formatTime('HH:mm') }}
          </div>

          <!-- Will be added if a content is set -->
          <div class="vuecal__event-content">
            <i class="icon material-icons">{{ event.content }}</i>
          </div>
        </template>
      </vue-cal>
    </div>
  </div>
</template>

<script>
import SimpleTypeahead from 'vue3-simple-typeahead';

import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

import { debounce } from "debounce";
import moment from 'moment';

import { db, onSnapshot, collection, query, where, doc, getDoc, addDoc, updateDoc, deleteDoc, getDocs, log } from "@/firebase";

export default {
  name: "VueCalendar",
  components: { VueCal, SimpleTypeahead },
  data: () => ({
    activeView: 'week',
    disableViews: [],
    events: [],
    recipes: [],
    selectedEvent: null,
    editableEvents: { title: true, drag: true, resize: true, delete: true, create: true }
  }),
  mounted() {
    this.resizeHandler();
  },
  async beforeMount() {
    window.addEventListener("resize", this.resizeHandler);

    if (this.$user) {
      const q = query(collection(db, "events"), where("user", "==", this.$user.uid));
      this.unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { newIndex, oldIndex, doc, type } = change;

          let e = doc.data();
          e.start = moment(e.start).format('YYYY-MM-DD HH:mm');
          e.end = moment(e.end).format('YYYY-MM-DD HH:mm');

          if (type === 'added') {
            this.events.splice(newIndex, 0, e)
          } else if (type === 'modified') {
            this.events.splice(oldIndex, 1)
            this.events.splice(newIndex, 0, e)
          } else if (type === 'removed') {
            this.events.splice(oldIndex, 1)
          }
        });
      }, (error) => {
        console.error(error);
      });

      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();
      
      const receipeQ = query(collection(db, "recipes"), where('user', 'in', [this.$user.uid, this.userDoc.household]));
      const querySnapshot = await getDocs(receipeQ);
      
      let self = this;

      querySnapshot.forEach((docu) => {
        self.recipes.push(docu.data().title);
      });
    }
  },
  async beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);    

    this.unsubscribe();
  }, 
  methods: {
    resizeHandler: debounce(async function() {
      if(window.innerWidth <= 550) {
        this.activeView = 'day';
        this.disableViews = ['years', 'week'];
        this.editableEvents = { title: true, drag: false, resize: false, delete: true, create: false };
      } else {
        this.activeView = 'week';
        this.disableViews = [];
        this.editableEvents = { title: true, drag: true, resize: true, delete: true, create: true };
      }
    }, 500),
    async onEventChange(events) {
      if(events.originalEvent) {
        const docRef = doc(db, "events", events.event.did);

        await updateDoc(docRef, {
          start: moment(events.event.start).valueOf(),
          end: moment(events.event.end).valueOf(),
          title: events.event.title,
          content: events.event.content,
          class: events.event.class
        });

        log('Termin bearbeitet', 'edit_calendar', 'warning', this.$user.uid);
      }
    },
    async onEventDelete(event) {
      await deleteDoc(doc(db, "events", event.did));

      log('Termin entfernt', 'event_busy', 'danger', this.$user.uid);
    },
    async onEventCreate(event) {
      event.title = 'Neuer Termin...';
      return event;
    },
    async onEventDragCreate(event) {
      await addDoc(collection(db, "events"),       
      {
        _eid: event._eid,
        start: moment(event.start).valueOf(),
        end: moment(event.end).valueOf(),
        title: event.title,
        user: this.$user.uid,
        content: 'event',
        class: 'event'
      }).then(async docRef => {
        await updateDoc(docRef, {
          did: docRef.id
        });
      });
      
      log('Termin hinzugefügt', 'event_available', 'success', this.$user.uid);

      return event;
    },
    async onEventTitleChange(events) {
      const docRef = doc(db, "events", events.event.did);

      await updateDoc(docRef, {
        start: moment(events.event.start).valueOf(),
        end: moment(events.event.end).valueOf(),
        title: events.event.title,
        content: events.event.content,
        class: events.event.class
      });

      log('Termin bearbeitet', 'edit_calendar', 'warning', this.$user.uid);
    },
    onEventFocus(event) {
      var self = this;
      setTimeout(function() {
        self.selectedEvent = event;
      }, 100);
    },
    onBlur(input) {
      var self = this;

      if(self.selectedEvent && input.input != "") {
        this.events.forEach((event) => {
          if(event.did == self.selectedEvent.did) {
            event.title = input.input;
            self.selectedEvent.title = input.input;

            if(self.recipes.includes(input.input)) {
              self.selectedEvent.content = 'restaurant';
              self.selectedEvent.class = 'restaurant';
            } else {
              self.selectedEvent.content = 'event';
              self.selectedEvent.class = 'event';
            }
          }
        })

        this.onEventChange({event: this.selectedEvent, originalEvent: this.selectedEvent});
      }
    }
  },
};
</script>
<style lang="scss">
.fc .fc-button-primary {
  background: #4caf50 !important;
  border-color: #4caf50 !important;
}
.fc .fc-button-primary:hover {
  background: #4caf50 !important;
  border-color: #4caf50 !important;
}
.vuecal {
  box-shadow: none;

  .vuecal__menu {
    background: none;
  }

  .vuecal__title-bar, .vuecal__menu {
    background-image: linear-gradient(90deg, #49a3f1 0%, #1a73e8 100%);
    
    color: rgba(255, 255, 255, 0.9);

    button {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .vuecal__menu {
    margin-top: -1.5rem;

    margin-right: 1rem;
    margin-left: 1rem;

    position: relative;
    z-index: 3;

    border-radius: 0.75rem 0.75rem 0 0;
  }

  .vuecal__title-bar {
    margin-right: 1rem;
    margin-left: 1rem;

    z-index: 2;

    border-radius: 0 0 0.75rem 0.75rem;

    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 188 212 / 40%);
  }

  .vuecal__event .vuecal__event-delete {
    transform: translateY(0);
    z-index: 1;
    display: none;
  }
  .vuecal__event--deletable .vuecal__event-delete {
    display: flex;
  }
}
.vuecal__event-content {
    .icon {
      font-size: 30px;
    }
}
.vuecal__event {
  overflow: visible;

  &.event, &.restaurant {
    background-color: #d07d1c;
    border: 1px solid #ce5f16;
    color: #fff;

    .icon {
      color: #fff;
    }
  }
  &.restaurant {
    background-color: #43a047;
    border: 1px solid #66bb6a;
  }
}
</style>
